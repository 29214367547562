import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { ProjServices } from "../../../apis/service/ProjServices";
import { AuthServices } from "../../../apis/service/AuthServices";
import toast from "react-hot-toast";
import { ProjectHourService } from "../../../apis/service/ProjectHourServices";
import DelayedProject from "../../../Components/delayedProject";
import ExportData from "../../../Components/exportData";
import ProjectStatus from "../../../Components/projectStatus";
import Cookies from "universal-cookie";

export default function Projects() {
  const [allProjects, setAllProjects] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [allSelectedProjects, setAllSelectedProjects] = useState([]);
  const [disable, setDisable] = useState(true);
  const [selectedProject, setSelectedProject] = useState(0);
  const [precentage, setPrecentage] = useState(0);
  const [title, setTitle] = useState("");
  const cookies = new Cookies(null, { path: "/" });

  const getAllProjects = () => {
    ProjServices.UserProjects()
      .then((data) => {
        console.log(data);
        setAvailableProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
    ProjServices.getAllProjects()
      .then((res) => {
        setAllProjects(res.all_user_related_projects);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllProjects();
    setTitle(cookies.get("title"));
  }, []);

  const handleProjectSelectionButton = (index) => {
    let x = [...allProjects];

    x[index].user_has_project = !x[index].user_has_project;
    setDisable(false);

    setAllProjects(x);
  };

  useEffect(() => {
    setAllSelectedProjects(allProjects.filter((item) => item.user_has_project));
  }, [allProjects]);

  const handleProjectSubmit = () => {
    setDisable(true);
    const projectsIDs = allSelectedProjects.map((item) => item.id);
    const data = { projects: projectsIDs };
    AuthServices.updateMe(data)
      .then(() => {
        toast.success("Projects submitted successfully!");
        setDisable(false);
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Projects submitted fail!");
        setDisable(false);
      });
  };

  const handleSubmitProjectsCompletion = () => {
    const data = {
      percentage_complete: Number(precentage),
      project: Number(selectedProject),
    };
    ProjectHourService.addProjectCompletion(data)
      .then(() => {
        toast.success("success!");
        setDisable(false);
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("failed!");
        setDisable(false);
      });
  };

  // Function to call ProjectsExport API

  return (
    <div>
      {/* Precentage of completion */}
      {title === "Project Manager" && (
        <div className={styles.container}>
          <div className={styles.projectCompletion}>
            <h2 className={styles.title}>Logical Percentage of Completion</h2>
            <div className={styles.contentCon}>
              <div className={styles.contentCard}>
                <div className={styles.content}>
                  <p>Choose the project:</p>
                  <select
                    className={styles.sel}
                    id="dateSelect"
                    value={selectedProject}
                    onChange={(e) => {
                      setSelectedProject(e.target.value);
                    }}
                  >
                    <option value={0}>Select your option</option>
                    {availableProjects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.content}>
                  <p>Type your estimated percentage:</p>
                  <input
                    type="number"
                    id="text"
                    name="precentageNumber"
                    onChange={(e) => setPrecentage(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.submitButton}>
              <button onClick={handleSubmitProjectsCompletion}>
                Submit Your Percentage
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Project Export Data Section */}
      {title === "Project Manager" && <DelayedProject />}

      {/*Project Status */}
      {title === "Project Manager" && <ProjectStatus />}

      {title === "Project Manager" && <ExportData />}
      <div className={styles.container}>
        <div className={styles.contentCard}>
          {allProjects?.map((item, index) => (
            <>
              <button
                onClick={() => handleProjectSelectionButton(index)}
                className={`${styles.availableProjectButton} 
            
            ${item?.user_has_project ? styles.already : null}
            `}
              >
                {item.name}
              </button>
            </>
          ))}
        </div>
        <div className={styles.submitButton}>
          <button disabled={disable} onClick={handleProjectSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
