import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./Login.module.css";
import { AuthServices } from "../../../apis/service/AuthServices";
import { useNavigate } from "react-router-dom";
import bit68Login from "../../../assets/bit68Login.png";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import Cookies from "universal-cookie";


function Login({ onLogin }) {
  const cookies = new Cookies(null, { path: '/' });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Add the loading state
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate(); // Get the navigate function


  const handleLogin = async (data) => {
    const { email, password } = data;
    let userData; // Define userData variable

    setLoading(true); // Show loading spinner

    // localStorage.removeItem("bearer");
    let requestData = {
      email,
      password,
    };

    try {
      const res = await AuthServices.UserLogin(requestData);
      localStorage.setItem("bearer", res.access_token);
      if (res.refresh_token) {
        // console.log("login refresh");
        localStorage.setItem("refresh", res.refresh_token);
      }

      // Attempt to get user data after successful login
      userData = await AuthServices.getUserData();
      // console.log(userData, "userdata");
      if (userData.username) {
        onLogin(userData);
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("username", userData.username);
        cookies.set("title", userData.title_name);
        cookies.set("isAdmin", userData.is_board_member);
        // console.log("username:", userData.username);
        // console.log("title:", userData.title_name);
        navigate("/");
      }
    } catch (error) {
      console.error("Error: ", error);

      setErrorMessage("Unable to log in with provided credentials");
    } finally {
      setLoading(false); // Hide loading spinner
    }

    if (userData) {
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("username", userData.username);
      cookies.set("title", userData.title_name);
    }
  };
  useEffect(() => {


  }, []);

  return (
    <div className={styles.loginContainer}>
      <div className={styles.Card}>
        <div className={styles.Logo}>
          <img alt="logo" src={bit68Login}></img>
        </div>
        <form className={styles.fields} onSubmit={handleSubmit(handleLogin)}>
          <input
            autoFocus
            id="email"
            type="text"
            placeholder="email"
            {...register("email", { required: true })}
          />
          <input
            id="password"
            type="password"
            placeholder="Password"
            {...register("password", { required: true })}
          />

          {errorMessage && (
            <div className={styles.errorMessage}>
              <p>{errorMessage}</p>
            </div>
          )}

          <button type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
